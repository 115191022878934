

export const sendSlackNotification =  (msgObj: any) => {

        // return await fetch('https://us-central1-parkable-dev.cloudfunctions.net/logSlackMessage', {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json'
        //     },
        //     body: "{test: \"test\"}"
        // });

    var request = require('request');
    var options = {
        'method': 'POST',
        'url': 'https://us-central1-parkable-dev.cloudfunctions.net/logSlackMessage',
        'headers': {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(msgObj)

    };
    request(options, function (error: any, response: any) {
        if (error) console.log("error", error);
        console.log(response.body);
    });
}