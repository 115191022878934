import React from "react";
import styles from "./Spinner.module.scss";

export const Spinner = () => {

    const spinner = (
        <div className={styles.spinner}>
            <svg
                viewBox={"0 0 24 24"}
                xmlns={"http://www.w3.org/2000/svg"}
                className={styles.spinner}>
                <ellipse
                    cx={"12"}
                    cy={"12"}
                    rx={"10"}
                    ry={"10"}
                    className={styles.spinnerEllipse}
                />
            </svg>
        </div>
    );

    return spinner;
};
